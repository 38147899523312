



































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, Log, Pagination } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    Nav,
  },
  data() {
    return {
      busy: false,
      logs: [] as Log[],
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      search: '',
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    this.fetchLogs()
  },
  methods: {
    fetchLogs() {
      this.logs = [] as Log[]
      const loading = this.$loading({
        target: '#logTable',
        text: 'Chargement des données...',
      })

      this.$api
        .get('/log/list', {
          params: {
            page: this.page,
            search: this.search,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.logs = apiResponse.data.logs

          const pagination = apiResponse.data.pagination as Pagination

          this.activePage = pagination.current
          this.itemsTotal = pagination.totalCount
          this.numPages = pagination.pageCount
        })
        .finally(() => {
          loading.close()
        })
    },
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        page: this.page,
        search: this.search,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        const day = new Intl.DateTimeFormat('fr-FR', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        }).format(date)
        const hour = new Intl.DateTimeFormat('fr-FR', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(date)

        return `${day} à ${hour}`
      } else {
        return '-'
      }
    },
  },
})
